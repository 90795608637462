import loadCss from "@style";

// make sure the CSS is loaded before we start the app
// because "./theme-colors.tsx" depends on it
loadCss.then(() => {
  import("./application");
});

if (process.env.NODE_ENV !== "production") {
  if (module.hot) {
    module.hot.accept();
  }
}
