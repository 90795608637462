/**
 * This module imports the appropriate theme CSS file.
 *
 * IMPORTANT: Do not add imports to this file because CSS need to be imported
 * before the modules that use THEME_COLOR are referenced.
 */
import { BRANDING_OPTIONS } from "@brandingConfig";
const THEME_NAME = BRANDING_OPTIONS.themeName || "default";

export default import(
  /* webpackChunkName: "[request]" */ "./index." + THEME_NAME + ".less"
);
