// IMPORTANT: do not add imports to this file

export interface BrandingConfig {
  title?: string;
  favicons?: boolean;
  css: {
    [key: string]: string;
  };
  classNames: {
    pagerBtn?: string;
    pagerBtnActive?: string;
    forcedBtnStylePrimary?: string;
    forcedBtnStyleSuccess?: string;
    forcedBtnStyleWarning?: string;
    forcedBtnStyleDanger?: string;
  };
  options: {
    themeName?: string;
    disableColorIconColors?: boolean;
  };
}

const defaultConfig: BrandingConfig = {
  title: "mpleo",
  css: {},
  classNames: {},
  options: {},
};

const BRANDING_CONFIG: BrandingConfig = Object.assign(
  {},
  defaultConfig,
  window["__branding__"]
);

export const BRANDING_TITLE = BRANDING_CONFIG.title;
export const BRANDING_CSS = BRANDING_CONFIG.css;
export const BRANDING_CLASNAMES = BRANDING_CONFIG.classNames;
export const BRANDING_OPTIONS = BRANDING_CONFIG.options;
